@import "npm:modern-normalize/modern-normalize.css";

html {
    font-size: 62.5%;
}

h1 {
    font-size: 2.6rem;
    margin-top: 2.6rem;
    margin-bottom: 0rem;
}
