@import "./base.css";

body {
    font-size: 2.4rem;
    color: #444;
    background-color: #f8f8f8;
    animation: brighten 0.8s ease-in 0s backwards;
}

a {
    color: #256f82;
    text-decoration: none;
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

#content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 1rem;
}

@media screen and (min-height: 800px) {
    #content {
        padding-bottom: 12rem;
    }
}

#head {
    animation: fadein 0.4s ease-in 0s backwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes brighten {
    from {
        background-color: #d2d2c1;
    }
    to {
        background-color: #f8f8f8;
    }
}

@keyframes darken {
    from {
        background-color: #44413d;
    }
    to {
        background-color: #2d2b29;
    }
}

#mid {
    animation: slidein 0.4s ease-in 0.2s backwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#tail {
    animation: slidein 0.4s ease-in 0.6s backwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.misc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer {
    font-size: 1rem;
    margin: 1rem;
}

@keyframes slidein {
    from {
        opacity: 0;
        transform: translate3d(0, -25px, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

#avatar img {
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 1.5px;
    border-color: white;
}

#desc {
    font-size: 1.6rem;
    font-weight: 300;
    text-align: left;
    line-height: 2.6rem;
    max-width: 50rem;
}

#email {
    word-break: break-all;
    font-size: 1.8rem;
}

.links a {
    border: 0;
    padding: 0.5rem;
    color: black;
    text-decoration: none;
}

.links a img,
.links a svg {
    height: 3rem;
    width: 3rem;
}

#github-i {
    fill: #1b1f23;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    a {
        color: #5aa3b5;
    }

    body {
        background-color: #2d2b29;
        color: white;
        animation: darken 0.8s ease-in 0s backwards;
    }

    #github-i {
        fill: white;
    }
}
